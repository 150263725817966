/* eslint-disable max-len */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type {
  ThemeModes,
  ISearchFilter,
  IMessages,
  IProfile,
} from 'types/globals';
import {
  countriesByContinent,
} from 'types/globals';
import { updateSessionStorageData } from 'utils/helpers';
import type { AccountEntity } from '../../../../../server/src/models/Account';

interface InitialState {
  isFetchingData: boolean,
  themeMode: ThemeModes,
  myProfile: null|IProfile,
  searchFilter: ISearchFilter
  advancedSearch: boolean, // only filter with advancedSearch options if this is true
  unReadMessages: number,
  messages: null|IMessages[],
  newLikes: number,
  newProfileViews: number,
  myAccount: AccountEntity|null,
  systemMessage: string|Array<string>,
  likeWasSent: boolean,
  showChatApp: boolean,
  chatData: any,
}

// update some values with data from preferences
const initialSearchFilterState = (): ISearchFilter => ({
  gender: 'female',
  continent: 'All',
  country: 'All',
  city: 'All',
  minAge: 25,
  maxAge: 45,
  relationshipStatus: 'All',
  educationLevel: 'All',
  // language: 'All', // One of the language(s) spoken by the user
  ethnicity: 'All',
  // minHeight: 120, // Height in centimeters
  bodyType: 'All',
  attractiveness: 'All',
  drinkingHabits: 'All',
  // photoOnly: false,
  hasKids: 'All',
  wantKids: 'All',
});

const initialState = (): InitialState => ({
  isFetchingData: false,
  themeMode: 'light',
  searchFilter: initialSearchFilterState(),
  myProfile: null,
  advancedSearch: false,
  unReadMessages: 0,
  messages: null,
  newLikes: 0, // set to 0
  newProfileViews: 0, // set to 0
  myAccount: null,
  systemMessage: '',
  likeWasSent: false,
  showChatApp: false,
  chatData: {},
});

export const contextSlice = createSlice({
  name: 'Context',
  initialState: initialState(),
  reducers: {
    setIsFetchingData: (state, action: PayloadAction<boolean>) => {
      state.isFetchingData = action.payload;
    },
    setThemeMode: (state, action: PayloadAction<ThemeModes>) => {
      state.themeMode = action.payload;
      updateSessionStorageData({ THEME_MODE: action.payload });
    },
    setSearchFilter: (state, action: PayloadAction<ISearchFilter>) => {
      state.searchFilter = { ...state.searchFilter, ...action.payload };
    },
    setAdvancedSearch: (state, action: PayloadAction<boolean>) => {
      state.advancedSearch = action.payload;
    },
    setMessages: (state, action: PayloadAction<IMessages[]>) => {
      state.messages = action.payload;
    },
    setUnReadMessages: (state, action: PayloadAction<number>) => {
      state.unReadMessages = action.payload;
    },
    setNewLikes: (state, action: PayloadAction<number>) => {
      state.newLikes = action.payload;
    },
    setNewProfileViews: (state, action: PayloadAction<number>) => {
      state.newProfileViews = action.payload;
    },
    setMyAccount: (state, action: PayloadAction<AccountEntity>) => {
      state.myAccount = action.payload;
    },
    setMyProfile: (state, action: PayloadAction<IProfile>) => {
      const mergedPreferences = mergeArrays(action.payload.preferences, window.__INIT_CONNECT_DATA__().extraSignUpData);
      // extraPreferences might be added on top later
      const mergeWithWhatExtraPreferenceData = {
        ...action.payload,
        preferences: mergedPreferences,
      };
      state.myProfile = mergeWithWhatExtraPreferenceData;
      // also add preferences to searchFilter
      // const relationshipStatus = action.payload.preferences.find((f) => f.key === preferences.RelationshipStatus.key) as any;
      // const educationLevel = action.payload.preferences.find((f) => f.key === preferences.EducationLevel.key) as any;
      // const ethnicity = action.payload.preferences.find((f) => f.key === preferences.Ethnicity.key) as any;
      // // const minHeight = action.payload.preferences.find((f) => f.key === preferences.Height.key) as any;
      // const bodyType = action.payload.preferences.find((f) => f.key === preferences.BodyType.key) as any;
      // const attractiveness = action.payload.preferences.find((f) => f.key === preferences.Attractiveness.key) as any;
      // const drinkingHabits = action.payload.preferences.find((f) => f.key === preferences.DrinkingHabits.key) as any;
      // const hasKids = action.payload.preferences.find((f) => f.key === preferences.HasKids.key) as any;
      // const wantKids = action.payload.preferences.find((f) => f.key === preferences.WantKids.key) as any;
      const ageInterval = action.payload.preferences.find((f) => f.key === 'ageInterval') as any;
      let minAge = 25;
      let maxAge = 40;
      if (ageInterval) {
        const splitAge = ageInterval.values[1][0].split(' - ');
        minAge = Number(splitAge[0]);
        maxAge = Number(splitAge[1]);
      }
      state.searchFilter = {
        ...state.searchFilter,
        minAge,
        maxAge,
        gender: action.payload.gender === 'male' ? 'female' : 'male',
        // skip matchlocation filter for now..
        // ...window.__INIT_CONNECT_DATA__().matchLocation !== 'All' && action.payload.gender === 'male' && { continent: findContinent(window.__INIT_CONNECT_DATA__().matchLocation) },
        // ...relationshipStatus && { relationshipStatus: firstInArrOrValue(relationshipStatus.values[1]) || firstInArrOrValue(relationshipStatus.values[0]) },
        // ...educationLevel && { educationLevel: firstInArrOrValue(educationLevel.values[1]) || firstInArrOrValue(educationLevel.values[0]) },
        // ...ethnicity && { ethnicity: firstInArrOrValue(ethnicity.values[1]) || firstInArrOrValue(ethnicity.values[0]) },
        // // ...minHeight && { minHeight: firstInArrOrValue(minHeight.values[1]) || firstInArrOrValue(minHeight.values[0]) },
        // ...bodyType && { bodyType: firstInArrOrValue(bodyType.values[1]) || firstInArrOrValue(bodyType.values[0]) },
        // ...attractiveness && { attractiveness: firstInArrOrValue(attractiveness.values[1]) || firstInArrOrValue(attractiveness.values[0]) },
        // ...drinkingHabits && { drinkingHabits: firstInArrOrValue(drinkingHabits.values[1]) || firstInArrOrValue(drinkingHabits.values[0]) },
        // ...hasKids && { hasKids: firstInArrOrValue(hasKids.values[1]) || firstInArrOrValue(hasKids.values[0]) },
        // ...wantKids && { wantKids: firstInArrOrValue(wantKids.values[1]) || firstInArrOrValue(wantKids.values[0]) },
      };
    },
    setSystemMessage: (state, action: PayloadAction<string>) => {
      state.systemMessage = action.payload;
    },
    setLikeWasSent: (state, action: PayloadAction<boolean>) => {
      state.likeWasSent = action.payload;
    },
    setShowChatApp: (state, action: PayloadAction<boolean>) => {
      state.showChatApp = action.payload;
    },
    setChatData: (state, action: PayloadAction<any>) => {
      state.chatData = action.payload;
    },
  },
});

function mergeArrays(primaryArray, additionalArray) {
  const mergedArray = [...primaryArray];
  const addedKey: any[] = [];
  additionalArray.forEach((additionalObject) => {
    const keyWithoutPrefix = additionalObject.key.replace(/^preferences\.|(\.\d+)$/g, '');

    const isInPrimaryArr = primaryArray.some((s) => s.key === keyWithoutPrefix);
    if (isInPrimaryArr) return null;

    if (addedKey.includes(keyWithoutPrefix)) {
      const findObj = mergedArray.find((v) => v.key === keyWithoutPrefix);
      findObj.values.push(additionalObject.value);
    } else if (keyWithoutPrefix) {
      const mergedObject: any = {
        key: keyWithoutPrefix,
        label: [additionalObject.profileLabel],
        values: [additionalObject.value],
      };

      addedKey.push(keyWithoutPrefix);

      mergedArray.push(mergedObject);
    }
  });

  return mergedArray;
}

function findContinent(countryName) {
  if (countryName === 'Africa') return countryName;
  if (countryName === 'South America') return countryName;
  const country = countryName.trim();
  let result = 'All';
  // eslint-disable-next-line no-restricted-syntax
  for (const continentObj of countriesByContinent) {
    // eslint-disable-next-line arrow-parens
    const foundCountry = continentObj.countries.find(c => c.label === country);
    if (foundCountry) {
      result = continentObj.continent;
    }
  }

  return result;
}

export const contextActions = { ...contextSlice.actions };

export default contextSlice.reducer;
