/* eslint-disable quotes */
/* eslint-disable max-len */
import {
  WebStorageKeys,
  countriesByContinent,
} from 'types/globals';
import type {
  ISessionStorageData,
  ISearchFilter,
  IListProfile,
} from 'types/globals';
import sp from 'types/sharedPreferences';
import { API } from 'service/service';

const preferences = sp();

const routeToSignUp = () => {
  if (window.location.pathname.includes('/signup')) {
    return true;
  }
};

export const isAuth = async () => {
  const sessionData = getWebStorage(WebStorageKeys.SESSION_DATA) || {};
  const token = sessionData.USER_TOKEN;

  if (token) {
    const account = await API.getAccount();
    const isValidToken = account.statusCode === 200;
    let signUp: any = false;
    let isLanding: any = false;
    if (!isValidToken) {
      signUp = routeToSignUp();
      isLanding = window.location.pathname === '/';
    }
    return {
      ...sessionData,
      isAuthenticated: isValidToken,
      profileData: account.data.profile,
      accountData: account.data.account,
      signUp,
      isLanding,
    };
  }

  return {
    isAuthenticated: false,
    signUp: routeToSignUp(),
    isLanding: window.location.pathname === '/',
  };
};

export const setWebStorage = (key, v, localOny = false) => {
  const value = JSON.stringify(v);
  window.sessionStorage.setItem(key, value);
  if (!localOny) window.localStorage.setItem(key, value);
};

export const getWebStorage = (key) => {
  const storage = window.sessionStorage.getItem(key) || window.localStorage.getItem(key);
  if (storage === 'undefined') return null;
  return storage && JSON.parse(storage);
};

export const clearWebStorage = () => {
  sessionStorage.clear();
  localStorage.clear();
};

export const updateSessionStorageData = (newKeys: Partial<ISessionStorageData>) => {
  const currentStorage = getWebStorage(WebStorageKeys.SESSION_DATA);
  setWebStorage(
    WebStorageKeys.SESSION_DATA,
    {
      ...currentStorage,
      ...newKeys,
    } as ISessionStorageData,
  );
};

export const filterUniqueValues = (arr: any) => Array.from(new Set(arr));

const getStoredBasicSearch = () => getWebStorage(WebStorageKeys.SEARCH_BASIC_FILTER) || {};
export const getSearchFilterFields = (searchFilter: ISearchFilter, setNewSearchFilter: any) => ([
  {
    name: 'continent',
    label: 'Continent',
    callback: (e: any, field: any) => setNewSearchFilter(e.target.value, field),
    arrData: countriesByContinent.map((c) => c.continent),
    value: getStoredBasicSearch().continent || searchFilter.continent,
  },
  {
    name: 'country',
    label: 'Country',
    callback: (e: any, field: any) => setNewSearchFilter(e.target.value, field),
    arrData: searchFilter.continent === 'All'
      ? ['All', ...countriesByContinent.map((c) => [...c.countries]).flat().map((c: any) => c.label).sort()]
      : [
        'All',
        countriesByContinent.find((cc) => cc.continent === searchFilter.continent)?.countries.map((c: any) => c.label).sort(),
      ].flat(),
    // arrData: [],
    value: getStoredBasicSearch().country || searchFilter.country,
  },
  {
    name: preferences.Gender.key,
    label: 'Gender',
    callback: (e: any, field: any) => setNewSearchFilter(e.target.value, field),
    arrData: Object.values(preferences.Gender.values),
    value: getStoredBasicSearch().gender || searchFilter.gender,
    disabled: true,
  },
  {
    name: 'minAge',
    label: 'Minimum Age',
    callback: (e: any, field: any) => setNewSearchFilter(e.target.value, field),
    arrData: generateNumbersArray(18, 100),
    value: getStoredBasicSearch().minAge || searchFilter.minAge,
  },
  {
    name: 'maxAge',
    label: 'Maximum Age',
    callback: (e: any, field: any) => setNewSearchFilter(e.target.value, field),
    arrData: generateNumbersArray(18, 100),
    value: getStoredBasicSearch().maxAge || searchFilter.maxAge,
  },
  {
    name: preferences.RelationshipStatus.key,
    label: 'Relationship Status',
    callback: (e: any, field: any) => setNewSearchFilter(e.target.value, field),
    arrData: Object.values(preferences.RelationshipStatus.values),
    value: getStoredBasicSearch().relationshipStatus || searchFilter.relationshipStatus,
  },
  {
    name: preferences.EducationLevel.key,
    label: 'Education Level',
    callback: (e: any, field: any) => setNewSearchFilter(e.target.value, field),
    arrData: Object.values(preferences.EducationLevel.values),
    value: searchFilter.educationLevel,
    advanced: true,
  },
  // {
  //   name: preferences.Languages.key,
  //   label: 'Language',
  //   callback: (e: any, field: any) => setNewSearchFilter(e.target.value, field),
  //   arrData: Object.values(preferences.Languages.values),
  //   value: getStoredBasicSearch(). || searchFilter.language,
  //   advanced: true,
  // },
  {
    name: preferences.Ethnicity.key,
    label: 'Ethnicity',
    callback: (e: any, field: any) => setNewSearchFilter(e.target.value, field),
    arrData: Object.values(preferences.Ethnicity.values),
    value: searchFilter.ethnicity,
    advanced: true,
  },
  // {
  //   name: preferences.Height.key,
  //   label: 'Minimum Height (cm)',
  //   callback: (e: any, field: any) => setNewSearchFilter(e.target.value, field),
  //   arrData: Object.values(preferences.Height.values),
  //   value: getStoredBasicSearch(). || searchFilter.minHeight,
  //   advanced: true,
  // },
  {
    name: preferences.BodyType.key,
    label: 'Body Type',
    callback: (e: any, field: any) => setNewSearchFilter(e.target.value, field),
    arrData: Object.values(preferences.BodyType.values),
    value: searchFilter.bodyType,
    advanced: true,
  },
  {
    name: preferences.Attractiveness.key,
    label: 'Attractiveness',
    callback: (e: any, field: any) => setNewSearchFilter(e.target.value, field),
    arrData: Object.values(preferences.Attractiveness.values),
    value: searchFilter.attractiveness,
    advanced: true,
    tooltip: 'According to themself',
  },
  {
    name: preferences.DrinkingHabits.key,
    label: 'Drinking Habits',
    callback: (e: any, field: any) => setNewSearchFilter(e.target.value, field),
    arrData: Object.values(preferences.DrinkingHabits.values),
    value: searchFilter.drinkingHabits,
    advanced: true,
  },
  // {
  //   name: 'photoOnly',
  //   label: 'Show only users with photos',
  //   callback: (e: any, field: any) => setNewSearchFilter(e.target.value, field),
  //   arrData: [true, false],
  //   value: getStoredBasicSearch(). || searchFilter.photoOnly,
  //   advanced: true,
  // },
  {
    name: preferences.HasKids.key,
    label: 'Has kids',
    callback: (e: any, field: any) => setNewSearchFilter(e.target.value, field),
    arrData: Object.values(preferences.HasKids.values),
    value: searchFilter.hasKids,
    advanced: true,
  },
  {
    name: preferences.WantKids.key,
    label: 'Want kids',
    callback: (e: any, field: any) => setNewSearchFilter(e.target.value, field),
    arrData: Object.values(preferences.WantKids.values),
    value: searchFilter.wantKids,
    advanced: true,
  },
]);

function generateNumbersArray(min, max) {
  const numbersArray: number[] = [];
  // eslint-disable-next-line no-plusplus
  for (let i = min; i <= max; i++) {
    numbersArray.push(i);
  }
  return numbersArray;
}

/*
 * Params @birtdate string as "yyyy-mm-dd"
 */
export const calculateAge = (birthdate: string) => {
  const today = new Date();
  const birthDate = new Date(birthdate);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    // eslint-disable-next-line no-plusplus
    age--;
  }
  return Number(age);
};

export const formatLastLogin = (lastLogin: number) => {
  const ONE_MINUTE = 60 * 1000;
  const ONE_HOUR = 60 * ONE_MINUTE;
  const ONE_DAY = 24 * ONE_HOUR;
  const ONE_WEEK = 7 * ONE_DAY;
  const SIX_MONTHS = 6 * 30 * ONE_DAY;

  const now = new Date().getTime();
  const timeDiff = now - lastLogin;

  if (timeDiff < 20 * ONE_MINUTE) {
    return 'Online Now';
  }
  if (timeDiff < ONE_HOUR) {
    return 'Less than one hour';
  }
  if (timeDiff < 5 * ONE_HOUR) {
    const hoursAgo = Math.floor(timeDiff / ONE_HOUR);
    return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
  }
  if (timeDiff < ONE_DAY) {
    return 'Today';
  }
  if (timeDiff < 2 * ONE_DAY) {
    return 'Yesterday';
  }
  if (timeDiff < ONE_WEEK) {
    return 'This week';
  }
  if (timeDiff < SIX_MONTHS) {
    const daysAgo = Math.floor(timeDiff / ONE_DAY);
    if (daysAgo < 7) {
      return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
    }
    const weeksAgo = Math.floor(timeDiff / ONE_WEEK);
    if (weeksAgo === 1) {
      return 'Last week';
    }
    return `${weeksAgo} week${weeksAgo > 1 ? 's' : ''} ago`;
  } if (timeDiff >= SIX_MONTHS) {
    return 'More than 6 months';
  }
  const lastLoginDate = new Date(lastLogin);
  const date = lastLoginDate.getDate().toString().padStart(2, '0');
  const month = (lastLoginDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
  const year = lastLoginDate.getFullYear();
  return `${date}/${month}/${year}`;
};

export const setProfileVisit = (userId: string) => {
  setWebStorage(WebStorageKeys.PROFILE_VISIT_ID, userId);
};

export const setConversationUserId = (userId: string) => {
  setWebStorage(WebStorageKeys.CHAT_USER_ID, userId);
};

export const setConversationProfile = (profile: IListProfile & { pictures?: any }) => {
  // ugly hack for adding missing data to conversation.
  if (typeof profile === 'object' && profile !== null) {
    if (Object.prototype.hasOwnProperty.call(profile, 'pictures') && !Object.prototype.hasOwnProperty.call(profile, 'imageSrc')) {
      const mainPic = profile.pictures.find((p) => p.main);
      if (mainPic) {
        profile.imageSrc = mainPic.url;
      }
    }
  }
  setWebStorage(WebStorageKeys.CHAT_USER_PROFILE_DATA, profile);
};

export function formatTime(timestamp) {
  const currentDate = new Date();
  const inputDate = new Date(timestamp);
  const timeDifference = currentDate.getTime() - inputDate.getTime();
  const oneDay = 24 * 60 * 60 * 1000;

  if (timeDifference < oneDay) {
    // Today
    return `Today ${formatHHMM(inputDate)}`;
  } if (timeDifference < 2 * oneDay) {
    // Yesterday
    return `Yesterday ${formatHHMM(inputDate)}`;
  } if (timeDifference < 14 * oneDay) {
    // X days ago
    const daysAgo = Math.floor(timeDifference / oneDay);
    return `${daysAgo} days ago ${formatHHMM(inputDate)}`;
  }
  // DD/MM/YYYY
  const day = inputDate.getDate();
  const month = inputDate.getMonth() + 1; // Months are zero-based
  const year = inputDate.getFullYear();
  return `${formatTwoDigits(day)}/${formatTwoDigits(month)}/${year} ${formatHHMM(inputDate)}`;
}

function formatHHMM(date) {
  const hours = formatTwoDigits(date.getHours());
  const minutes = formatTwoDigits(date.getMinutes());
  return `${hours}:${minutes}`;
}

function formatTwoDigits(number) {
  return number < 10 ? `0${number}` : number;
}

export const encryptPwdV1 = (pwd) => pwd
  .replace(/1/g, 'Z').replace(/2/g, '9').replace(/3/g, 'G').replace(/4/g, 'F')
  .replace(/5/g, '3')
  .replace(/6/g, 'K')
  .replace(/7/g, '3')
  .replace(/8/g, 'C')
  .replace(/9/g, 'W')
  .replace(/0/g, 'J')
  .replace(/q/g, 'Z')
  .replace(/w/g, 'R')
  .replace(/e/g, 'T')
  .replace(/r/g, 'M')
  .replace(/t/g, 'N')
  .replace(/y/g, '7')
  .replace(/u/g, '9')
  .replace(/i/g, '9')
  .replace(/o/g, 'I')
  .replace(/p/g, '4')
  .replace(/å/g, '8')
  .replace(/a/g, 'W')
  .replace(/s/g, '7')
  .replace(/d/g, '8')
  .replace(/f/g, 'C')
  .replace(/g/g, 'R')
  .replace(/h/g, '9')
  .replace(/j/g, '6')
  .replace(/k/g, 'R')
  .replace(/l/g, 'L')
  .replace(/ö/g, 'C')
  .replace(/ä/g, 'E')
  .replace(/z/g, 'V')
  .replace(/x/g, 'A')
  .replace(/c/g, 'W')
  .replace(/v/g, 'G')
  .replace(/b/g, 'M')
  .replace(/g/g, '5')
  .replace(/m/g, '7')
  .replace(/,/g, 'B')
  .replace(/-/g, 'S');

export const formatString = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export function filterUniqueKeys(data, uniqueKey) {
  const uniqueKeys = new Set();
  const uniqueObjects: any[] = [];

  data.forEach((item) => {
    if (!uniqueKeys.has(item[uniqueKey])) {
      uniqueKeys.add(item[uniqueKey]);
      uniqueObjects.push(item);
    }
  });

  return uniqueObjects;
}

export const generateAgeArray = () => {
  const ageArray: any[] = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 18; i <= 100; i++) {
    ageArray.push(i);
  }
  return ageArray;
};

export const getWebsiteName = () => {
  const host = window.location.hostname.split('.')[1];
  if (host === 'jwdate' || window.location.hostname === 'localhost') return 'JWDate.org';
  if (host.includes('date')) {
    const a = host.split('date');
    const name = a.map((m) => capitalizeFirstLetter(m) || 'Date').join('');
    return name;
  }
  return capitalizeFirstLetter(host);
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const getUrlParam = (key: string) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(key);
};

const baseInit = {
  type: 'Birdy',
  indexTitle: 'Birdy | Birdy namns',
  metaDescription: 'Exclusive Birdy Site',
  image: 'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Fmulti-bg.webp?alt=media&token=94edae14-c8df-4bd5-b197-595ccd07c260',
  landingBoxStyle: {
    background: 'none',
    filter: 'none',
  },
  bodyStyle: { background: 'linear-gradient(135deg, #c0dffb, #fbc4eb)' },
  loginButtonColor: '#ffffff',
  loginPicture: 'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Fwalking-beach.jpeg?alt=media&token=a0a91a26-6799-482a-a8ef-cb603b133a86',
  logoText: 'Birdy',
  logoStyle: () => ({
    fontSize: '28px',
    color: '#ff7b9c',
  }),
  landingButtonColor: '#ff7b9c',
  header: 'Header',
  subHeader: 'Subheader',
  logoUrl: null,
  poweredBy: 'Birdy.se',
  landingSectionTwoStyle: {},
  landingSectionTwoContent: [],
  blogUrl: '',
  blogCustomerId: '',
  landingSectionThreeStyle: {
    background: 'linear-gradient(135deg, #f1f1f1, #ffffff)',
    borderBottom: '1px solid grey',
  },
  extraPreferences: [],
  extraSignUpData: [],
  matchLocation: 'All',
  gVerificationScript: () => {},
  nothingFoundPicture: 'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Fnothing-found-gif.gif?alt=media&token=b0e13928-733d-4050-9fdd-6b965ed19a91',
  nothingFoundBroadenSearch: 'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Fbroaden-search.png?alt=media&token=933b170c-8c10-4fc9-9e71-1d6703948346',
  showArticles: true,
  locked: true,
};

const jdData = () => ({
  ...baseInit,
  type: 'jd',
  indexTitle: "JWDate | Free Dating Site for Jehovah's Witnesses Singles",
  metaDescription: 'JW Dating Site for JW singles only',
  metaKeywords: 'jw dating, jwdate, jwmatch, jw dating site, jw dating online',
  logoText: 'JWDate.org',
  header: 'Free JW Dating Site for JW Singles',
  subHeader: 'Then Jehovah God said: “It is not good for the man to continue to be alone.<br /> I am going to make a helper for him, as a complement of him.”',
  poweredBy: 'JWDate.org',
  landingSectionTwoContent: [
    {
      title: 'Discover Genuine Connections.',
      text: "As a JW, finding a partner isn't just a casual pursuit, it's a purposeful journey towards marriage. It's a challenge to find this level of sincerity elsewhere, which is why we've created a JW dating site where individuals with similar values can unite.",
    },
    {
      title: "Fast and Meaningful Matches.",
      text: "Connect with new JW singles in seconds, tailored to your preferences. We bring together people who share your high moral standards and lives by bible principals. This website is exclusively designed for authentic single Jehovah's Witnesses seeking genuine companionship.",
    },
    {
      title: 'Helping You In Your Search for Love.',
      text: "Your search for love is important, and we recognize the value of patience. We provide essential tools to help you connect with like-minded individuals and build friendships in an environment that is authentic, pure, and secure.",
    },
  ],
  blogUrl: 'https://www.blog.jwdate.org/',
  blogCustomerId: '31392486-614f-4a34-bd0f-273305505dc3_TID_-28',
  extraPreferences: [
    {
      key: 'wasDisfellowshipped',
      values: {
        Never: 'Never',
        IWasOnce: 'I was once',
        IBeenMoreThanOnce: 'I been more than once',
        AskLater: 'Ask later',
      },
      multiSelect: true,
    },
    {
      key: 'servingAs',
      values: {
        All: 'All',
        Publisher: 'Publisher',
        RegularPioneer: 'Regular pioneer',
        AuxiliaryPioneer: 'Auxiliary pionerr',
        MinisterialServant: 'Ministerial servant',
        Elder: 'Elder',
        CircuitOverseer: 'Circuit overseer',
        Bethelist: 'Bethelist',
        NotBaptizedYet: 'Not baptized yet',
      },
      multiSelect: true,
    },
  ],
  extraSignUpData: [],
  gVerificationScript: () => {
    const scriptTwo = document.createElement('script');
    scriptTwo.type = 'text/javascript';
    scriptTwo.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'AW-11397150353');`;

    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-11397150353';
    script.async = true;
    script.type = 'text/javascript';

    const adsenseScript = document.createElement('script');
    adsenseScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6562403025295904';
    adsenseScript.async = true;
    adsenseScript.type = 'text/javascript';
    adsenseScript.crossOrigin = 'anonymous';

    document.head.appendChild(scriptTwo);
    document.head.appendChild(script);
    document.head.appendChild(adsenseScript);
  },
  nothingFoundPicture: 'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Fnothing-found.png?alt=media&token=c43db3f3-8f2d-4bb4-8ff7-54d5a9bbbb8c',
  nothingFoundBroadenSearch: 'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Fno-search-results.png?alt=media&token=83777b7f-96df-48e8-a5db-7cd131f67b9a',
  showArticles: false,
  aboutUs: {
    title: "About JWDate.org",
    content: `<p>JWDate is an exclusive JW dating site for Jehovah's Witnesses, built and managed by Jehovah's Witnesses to help brothers and sisters connect worldwide and find their jw match.
</p>
<p>(This site is not owned by the official JW organisation)</p>
<p>
We want to bring together genuine JW singles and friends to meet in one place, in a clean and controlled online environment. We know navigating love as a JW single can be tough, finding the one can seem like
a forever search. Finding love online has become the new normal in society, but when it comes to dating as a JW it might add some extra complexity, we only want to date other Jehovah's witnesses who follows his high morals.
</p>
<p>Many brothers and sisters are single because they obey the command to marry "Only in the Lord". And so, let us embrace the digital assets and meet brothers and sisters (jw singles) online.
It might lead to unexpected outcomes. In all you do.. we hope you have a clean consciousness and can meet brothers and sister worldwide and potentially meet your
jw perfect match.
</p>
<p>
On this platform, we honor Jehovah's high morals just as you do. However, as always, remain vigilant and exercise caution in choosing whom you dedicate yourself to.
</p>
<p>
Good luck and happy dating!
</p>`,
  },
  locked: false,
});

const phillipineData = () => ({
  ...baseInit,
  type: 'pb',
  indexTitle: 'PhilippineBeauty | Meet Philippine Women and Foreign men',
  metaDescription: 'Official Dating Site To meet Philippine Women and Foreign men',
  image: getUrlParam('g') === 'w'
    ? 'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Ffilipin.jpeg?alt=media&token=0f64e338-698a-4c6c-97e8-21af589e2fb2'
    : 'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Ffilipina.jpeg?alt=media&token=052df4e9-e4b5-437c-b848-ae554b4ca6e9',
  bodyStyle: { background: 'linear-gradient(135deg, rgb(121 111 108), rgb(178 53 142))' },
  landingBoxStyle: { background: 'none!important', filter: 'none' },
  loginButtonColor: '#ffffff',
  loginPicture: [
    'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Fph-women.jpeg?alt=media&token=49195e43-de5a-4b98-b17d-8b0af8606a26',
    'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Flove3.jpeg?alt=media&token=eb617498-0701-4c7f-a301-43876339f319',
  ][Math.floor(Math.random() * 2)],
  logoText: 'PhilippineBeauty',
  logoStyle: () => ({
    fontSize: window.IS_BELOW_SM ? '20px' : '22px',
    color: 'rgb(121 111 108)',
  }),
  landingButtonColor: 'rgb(121 111 108)',
  header: getUrlParam('g') === 'w' ? 'Connect with Foreign Gentlemens' : 'Date Beautiful Philippine Women',
  subHeader: getUrlParam('g') === 'w' ? 'Discover Genuine Connections with Foreign Gentlemen Who Are Eager to Build Meaningful Relationships with Philippine Women' : 'Date Stunning Philippine Women Who Are Eager to Connect with Foreign Men for Marriage',
  poweredBy: 'PhilippineBeauty.com',
  landingSectionTwoStyle: {},
  landingSectionTwoContent: getUrlParam('g') === 'w' ? [
    {
      title: 'Explore Dating Connections with Foreign Gentlemen.',
      text: "Discover meaningful connections with gentlemen from around the world. Our platform is designed for Philippine women seeking genuine relationships and the opportunity to meet and connect with foreign men.",
    },
    {
      title: "Effortless Matching for International Romance.",
      text: "Experience a simple and efficient matching process that introduces you to compatible gentlemen from various cultures. Our platform makes it easy to connect with like-minded individuals who are genuinely interested in building a cross-cultural connection and a shared future.",
    },
    {
      title: 'Empower Your Search for Love Across Borders.',
      text: 'Take control of your dating life by using our advanced tools, allowing you to customize your search and to meet with foreign gentlemen who share your values. We are committed to providing a secure, respectful, and authentic space for you to create meaningful connections that transcend geographical boundaries.',
    },
  ] : [
    {
      title: 'Connect with Genuine Single Philippine Women.',
      text: "At our platform, we understand the importance of finding a life partner who shares your values and culture. Dating with sincere Philippine women looking for meaningful relationships and marriage.",
    },
    {
      title: "Effortless and Quick Matching.",
      text: "Discover compatible Philippine singles within moments based on your preferences. Our platform simplifies the process of dating, connecting you with like-minded individuals who are serious about building a future together.",
    },
    {
      title: 'Empowering Your Search for Love.',
      text: 'Utilize our advanced tools to refine your search and connect with Philippine women who match your criteria. We are committed to creating a genuine, respectful, and secure environment for you to form meaningful connections.',
    },
  ],
  blogUrl: 'https://www.blog.philippinebeauty.com/',
  blogCustomerId: '276a85c3-06b4-45e4-a409-ee8640cf48ad_TID_-18',
  landingSectionThreeStyle: {
    background: 'linear-gradient(135deg, #f1f1f1, #ffffff)',
    borderBottom: '1px solid grey',
  },
  matchLocation: getUrlParam('g') === 'w' ? 'All' : 'Philippines',
  gVerificationScript: () => {
    const metaTag = document.createElement('meta');
    metaTag.name = 'google-site-verification';
    metaTag.content = 'G1LTDYL6-1pSDkoTNZcHXn_7rtY07iwkM3GmEyyClME';
    const headElement = document.head || document.getElementsByTagName('head')[0];
    headElement.appendChild(metaTag);
  },
  locked: false,
  aboutUs: {
    title: "About PhilippineBeauty.com",
    content: `<p>PhilippineBeauty is an exclusive dating site connecting singles interested in dating Philippine women, designed and managed to facilitate meaningful connections among Philippine singles worldwide.
</p>
<p>
We aim to create a platform where genuine Philippine singles can meet in a secure online space. We understand that dating in the Philippines and finding the right partner can be challenging, which is why we provide a safe environment for individuals seeking to date Philippine women.
</p>
<p>Many individuals desire to date Philippine women with high moral values and cultural integrity. We respect and honor these values and provide a platform exclusively for Philippine singles looking for like-minded partners.
</p>
<p>
Some choose to remain single while searching for a partner who shares their beliefs and values. PhilippineBeauty.com allows you to embrace digital connections, potentially leading to unexpected but fulfilling relationships with Philippine women who share your aspirations.
</p>
<p>
We uphold the values important to the Philippine culture and hope that through our platform, you can connect with like-minded individuals globally, fostering meaningful relationships with Philippine singles.
</p>
<p>
While we promote a platform aligned with these values, we also encourage caution and mindfulness when connecting with others.
</p>
<p>
Best wishes and happy dating in your journey to meet and potentially marry a Philippine woman!
</p>`,
  },
});

const africanData = () => ({
  ...baseInit,
  type: 'qa',
  indexTitle: 'QueenOfAfrica | African Dating Site',
  metaDescription: 'Official Dating Site To meet African Women and Foreign men',
  image: getUrlParam('g') === 'm'
    ? 'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Fafrican-fine.png?alt=media&token=03af8de2-d948-4861-a9f7-f58982af30a6'
    : 'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Fafrican.jpeg?alt=media&token=073dff05-77ed-4adb-92af-e2adb6e2e5bd',
  bodyStyle: { background: 'linear-gradient(135deg, rgb(121 111 108), rgb(178 53 142))' },
  landingBoxStyle: { background: 'none!important', filter: 'none' },
  loginButtonColor: '#ffffff',
  loginBtnStyle: {
    background: '#444444',
  },
  loginPicture: [
    'https://imgs.search.brave.com/i-hz9n4Rz7Xw1GFkCmAoF50T08Si2kFC_1YW6w98iyU/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9pLnBp/bmltZy5jb20vb3Jp/Z2luYWxzL2JlLzFh/L2M2L2JlMWFjNjVh/MTRlYmU2ZTQxYWMz/ZDIzYmNhNDk4MWI4/LmpwZw',
    'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Fafrican-woman.png?alt=media&token=a7980c25-78fa-4df8-80e2-81d2a905ba6a',
    'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Fafrican-w.webp?alt=media&token=8a5f6eea-f8e9-40f4-8098-999cc2c9715d',
  ][Math.floor(Math.random() * 3)],
  // eslint-disable-next-line no-nested-ternary
  logoText: window.IS_BELOW_SM ? 'QueenOfAfrica' : (window.location.pathname === '/' ? 'QueenOfAfrica.org' : 'QueenOfAfrica'),
  // logoText: 'QueenOfAfrica',
  logoStyle: () => ({
    fontSize: window.IS_BELOW_SM ? '22px' : '24px',
    color: 'rgb(121 111 108)',
  }),
  landingButtonColor: 'rgb(121 111 108)',
  header: getUrlParam('g') === 'w' ? 'Connect with Foreign Gentlemen' : 'Nr. 1 African Dating Site',
  subHeader: getUrlParam('g') === 'w'
    ? 'Discover Genuine Connections with Foreign Gentlemen Who Are Eager to Build Meaningful Relationships with African Women'
    : 'Date Stunning African Women Who Are Eager to Connect with Foreign Men for Marriage',
  poweredBy: 'QueenOfAfrica.org',
  landingSectionTwoStyle: {},
  landingSectionTwoContent: getUrlParam('g') === 'w' ? [
    {
      title: 'Explore Dating Connections with Foreign Gentlemen.',
      text: "Discover meaningful connections with gentlemen from around the world. Our platform is designed for African women seeking genuine relationships and the opportunity to meet and date with foreign men.",
    },
    {
      title: "Effortless Matching for International Romance.",
      text: "Experience a simple and efficient matching process that introduces you to compatible gentlemen from various cultures. Our platform makes it easy to connect with like-minded individuals who are genuinely interested in building a cross-cultural connection and a shared future.",
    },
    {
      title: 'Empower Your Search for Love Across Borders.',
      text: 'Take control of your dating life using our advanced tools, allowing you to customize your search and connect with foreign gentlemen who share your values. We are committed to providing a secure, respectful, and authentic space for you to create meaningful connections that transcend geographical boundaries.',
    },
  ]
    : [
      {
        title: 'Meet Genuine African Women Singles.',
        text: "At our platform, we understand the importance of finding a life partner who shares your values and culture. Meet sincere African women looking for meaningful relationships and marriage.",
      },
      {
        title: "Effortless and Quick Matching.",
        text: "Discover compatible African singles within moments based on your preferences. Our platform simplifies the process of dating, connecting you with like-minded individuals who are serious about building a future together.",
      },
      {
        title: 'Top African Dating Site.',
        text: 'Utilize our advanced tools to refine your search and connect with African women who match your criteria. We are committed to creating a genuine, respectful, and secure environment for you to form meaningful dating connections.',
      },
    ],
  blogUrl: 'https://www.blog.queenofafrica.org',
  blogCustomerId: '45515dc1-23d8-45d8-9ed7-7d848855eaad_TID_-38',
  landingSectionThreeStyle: {
    background: 'linear-gradient(135deg, #f1f1f1, #ffffff)',
    borderBottom: '1px solid grey',
  },
  matchLocation: getUrlParam('g') === 'w' ? 'All' : 'Africa',
  extraPreferences: [
    {
      key: 'religion',
      values: {
        AskLater: 'Ask later',
        All: 'All',
        Christian: 'Christian',
        Muslim: 'Muslim',
        Hindu: 'Hindu',
        Buddhist: 'Buddhist',
        Jewish: 'Jewish',
        Sikh: 'Sikh',
        Other: 'Other',
        NotImportant: 'Not important',
      },
    },
  ],
  gVerificationScript: () => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-11397150353';

    // Append the script element to the head tag
    document.head.appendChild(script);

    // Create a new script element for the inline script
    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-11397150353');
    `;

    // Append the inline script element to the head tag
    document.head.appendChild(inlineScript);
  },
  locked: false,
  aboutUs: {
    title: "About QueenOfAfrica.org",
    content: `<p>QueenOfAfrica is an exclusive African dating site dedicated to fostering connections between singles seeking to date African women and foreign men, offering a platform designed to facilitate meaningful relationships between diverse cultures.
</p>
<p>
Our goal is to create a space where genuine connections can flourish between African women and foreign men in a safe and secure online environment. We understand the challenges of dating across cultures, especially when it comes to meeting African women, and we aim to provide a platform that bridges these gaps.
</p>
<p>For foreign men seeking to date African women and vice versa, cultural compatibility and mutual understanding are vital. We celebrate the rich diversity of African cultures and provide a platform exclusively for individuals interested in meeting African women online, fostering relationships built on respect and shared values.
</p>
<p>
Some individuals seek a partner who embodies the values and traditions of African culture, and QueenOfAfrica allows you to explore these connections, potentially leading to fulfilling relationships with African women who share your interests and values.
</p>
<p>
Through our platform, we encourage global connections between individuals interested in dating African women, providing a space to meet like-minded people and potentially find a life partner.
</p>
<p>
While we promote cultural exchange and understanding, we also advocate for caution and mindfulness when engaging with others on our platform.
</p>
<p>
Wishing you the best in your journey to meet and potentially marry an African woman through QueenOfAfrica!
</p>`,
  },
});

const globeRomance = () => ({
  ...baseInit,
  type: 'gr',
  indexTitle: 'GlobeRomance.com | International Dating Site',
  metaDescription: 'International Dating Site To Find Love Across Borders',
  loginPicture: [
    'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Flove4.avif?alt=media&token=e2b298a9-875d-4fa0-9878-e7c412557230',
    'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Flove3.jpeg?alt=media&token=eb617498-0701-4c7f-a301-43876339f319',
    'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Flove2.jpeg?alt=media&token=3c4074c8-aa55-4851-992e-950ed98a844c',
    'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Flove1.avif?alt=media&token=b847a24d-0573-42c9-943f-10e4fd37a6e7',
  ][Math.floor(Math.random() * 4)],
  // eslint-disable-next-line no-nested-ternary
  logoText: 'GlobeRomance',
  header: 'International Dating For Singles',
  subHeader: 'Meet Singles and Find Love with Individuals from Around the World for Dating and for Relationships and Marriage',
  poweredBy: 'GlobeRomance.com',
  logoStyle: () => ({
    fontSize: window.IS_BELOW_SM ? '22px' : '24px',
    color: baseInit.logoStyle().color,
  }),
  landingSectionTwoContent: [
    {
      title: 'Connect with Genuine Singles Internationally.',
      text: "At our platform, we understand the importance of dating and to find a life partner who shares your values. Meet sincere singles from diverse backgrounds looking for meaningful relationships and marriage.",
    },
    {
      title: "Effortless and Quick Matching.",
      text: "Discover compatible singles worldwide within moments based on your preferences. Our platform simplifies the process of dating, connecting you with like-minded individuals who are serious about building a future together.",
    },
    {
      title: 'Nr 1 International Dating Site.',
      text: 'Utilize our advanced tools to refine your search and connect with singles who match your criteria. We are committed to creating a genuine, respectful, and secure environment for you to form meaningful dating connections.',
    },
  ],
  extraPreferences: [
    {
      key: 'religion',
      values: {
        AskLater: 'Ask later',
        All: 'All',
        Christian: 'Christian',
        Muslim: 'Muslim',
        Hindu: 'Hindu',
        Buddhist: 'Buddhist',
        Jewish: 'Jewish',
        Sikh: 'Sikh',
        Other: 'Other',
        NotImportant: 'Not important',
      },
    },
  ],
  blogUrl: 'https://www.blog.globeromance.com',
  blogCustomerId: '99f21a3d-ed04-429b-ab6d-66cbd17726ee_TID_-38',
  locked: false,
  aboutUs: {
    title: "About GlobeRomance.com",
    content: `<p>GlobeRomance is an exclusive international dating site designed to connect singles worldwide, offering a platform crafted to facilitate meaningful connections among individuals seeking love and companionship across borders.
</p>
<p>
Our mission is to create a welcoming space where genuine connections can thrive among individuals looking to meet singles worldwide. We recognize the beauty of diverse cultures and aim to provide a platform that transcends geographical boundaries, fostering relationships that span continents.
</p>
<p>For those seeking love beyond borders and cultures, GlobeRomance is the place to be. We celebrate diversity and offer a platform exclusively for individuals interested in international dating online, encouraging connections based on mutual interests and shared values.
</p>
<p>
Some people dream of finding love with someone from a different part of the world, and GlobeRomance enables these connections, potentially leading to fulfilling relationships with like-minded singles from various corners of the globe.
</p>
<p>
Through our platform, we encourage global connections between individuals seeking to find love internationally, providing a space to meet people from diverse backgrounds and potentially find a life partner across continents.
</p>
<p>
While we promote the beauty of international dating, we also stress the importance of caution and mindfulness when building relationships with individuals from different cultures and regions.
</p>
<p>
Wishing you the best on your journey to find love and meet singles worldwide through GlobeRomance!
</p>`,
  },

});

const disabilityMatch = () => ({
  ...baseInit,
  type: 'dm',
  indexTitle: 'DisabilityMatch.Org | Dating For Disabled Individuals Online',
  metaDescription: 'Official Dating Site For Disabled People',
  // image: [
  //   'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Fdisabled-love-3.webp?alt=media&token=d8446958-808c-4285-b70d-bd6dd977e6c4',
  //   'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Fdisabled-love-2.webp?alt=media&token=ae3c4f8a-f12e-49c5-a2b8-1e960b35f9fc',
  // ][Math.floor(Math.random() * 2)],
  image: 'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Fmulti-bg.webp?alt=media&token=94edae14-c8df-4bd5-b197-595ccd07c260',
  loginPicture: [
    'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Fdisabled-love-7.webp?alt=media&token=9b944a9a-869b-401d-83dc-a03ad37cc19c',
    'https://firebasestorage.googleapis.com/v0/b/connect-platform-372bd.appspot.com/o/default%2Fdisabled-love-6.webp?alt=media&token=7df4cc58-2998-434b-b208-58a756c08439',
  ][Math.floor(Math.random() * 2)],
  bodyStyle: { background: 'linear-gradient(135deg, rgb(121 111 108), rgb(178 53 142))' },
  landingBoxStyle: { background: 'none!important', filter: 'none' },
  loginButtonColor: '#ffffff',
  loginBtnStyle: {
    background: '#444444',
  },
  // eslint-disable-next-line no-nested-ternary
  logoText: window.IS_BELOW_SM ? 'DisabilityMatch' : (window.location.pathname === '/' ? 'DisabilityMatch.org' : 'DisabilityMatch'),
  header: 'Online Dating For People With Disabilities',
  subHeader: 'Meet Other Singles With Disabilities Looking For Love and Connections Online',
  poweredBy: 'DisabilityMatch.Org',
  logoStyle: () => ({
    fontSize: window.IS_BELOW_SM ? '20px' : '22px',
    color: 'rgb(121 111 108)',
  }),
  landingSectionTwoContent: [
    {
      title: 'Connect with Authentic Individuals in the Disability Community.',
      text: "At our platform, we recognize the significance of finding a partner who understands and respects your journey. Meet genuine individuals from diverse backgrounds within the disability community seeking meaningful connections and committed relationships.",
    },
    {
      title: "Efficient and Tailored Matching for Your Needs.",
      text: "Discover compatible individuals within the disability community swiftly based on your preferences. Our platform streamlines the dating process, connecting you with like-minded people who are earnest about building strong and supportive relationships.",
    },
    {
      title: 'Empowering Your Quest for Love and Companionship.',
      text: 'Leverage our advanced tools designed to cater to the needs of the disability community, enabling you to find and connect with individuals who resonate with your lifestyle and aspirations. We are dedicated to fostering a genuine, inclusive, and safe space for you to establish meaningful connections.',
    },
  ],
  landingButtonColor: 'rgb(121 111 108)',
  extraPreferences: [
    {
      key: 'religion',
      values: {
        AskLater: 'Ask later',
        All: 'All',
        Christian: 'Christian',
        Muslim: 'Muslim',
        Hindu: 'Hindu',
        Buddhist: 'Buddhist',
        Jewish: 'Jewish',
        Sikh: 'Sikh',
        Other: 'Other',
        NotImportant: 'Not important',
      },
    },
  ],
  blogUrl: 'https://www.blog.disabilitymatch.org',
  blogCustomerId: 'cbf3765d-c12c-49ef-a2d8-3b5ac0f0ee1d_TID_-39',
  locked: false,
  aboutUs: {
    title: "About DisabilityMatch.com",
    content: `<p>DisabilityMatch is an exclusive disability dating site dedicated to fostering connections among disabled singles worldwide, offering a platform tailored to facilitate meaningful relationships within the disabled community.
</p>
<p>
Our primary goal is to create an inclusive space where genuine connections can flourish among disabled singles. We understand the unique challenges faced by disabled individuals in dating and relationships, and we strive to provide a safe and supportive online environment.
</p>
<p>For those seeking love and companionship within the disabled community, DisabilityMatch is here to connect you. We celebrate diversity and offer a platform exclusively for individuals interested in finding love for disabled people, encouraging connections based on mutual understanding and shared experiences.
</p>
<p>
Some disabled individuals seek meaningful relationships with others who understand and share similar life experiences. DisabilityMatch allows you to connect with disabled singles worldwide, potentially leading to fulfilling relationships with like-minded individuals within the international disabled dating community.
</p>
<p>
Through our platform, we aim to facilitate connections between disabled individuals globally, providing a supportive space to meet and potentially find a life partner within the disabled community.
</p>
<p>
While we advocate for inclusivity and support within the disabled community, we also emphasize the importance of caution and respect when building relationships online.
</p>
<p>
Wishing you the best in your journey to find love and meet disabled singles worldwide through DisabilityMatch!
</p>`,
  },

});

const adjustJData = (data) => {
  data.extraSignUpData = [
    {
      key: 'preferences.servingAs.0',
      label: 'What are you serving as?',
      validate: (val) => val,
      errMsg: 'Add your "Serving as" status',
      value: '',
      select: Object.values((<any>jdData().extraPreferences.find((f) => f.key === 'servingAs')).values).filter((v) => v !== 'All'),
      step: 3,
      profileLabel: 'Serving as',
    },
    {
      key: 'preferences.servingAs.1',
      label: 'What should your match be serving as?',
      validate: (val) => val,
      errMsg: 'Add your match "Serving as" status',
      value: 'All',
      select: Object.values((<any>jdData().extraPreferences.find((f) => f.key === 'servingAs')).values),
      step: 3,
      profileLabel: 'Serving as',
      multi: (<any>jdData().extraPreferences.find((f) => f.key === 'servingAs')),
    },
  ] as any;
  return data;
};

const addRelData = (data) => {
  data.extraSignUpData = [
    {
      key: 'preferences.religion.0',
      label: 'Your religion',
      validate: (val) => val,
      errMsg: 'Add your religion',
      value: 'Ask later',
      select: Object.values((<any>africanData().extraPreferences.find((f) => f.key === 'religion')).values).filter((v) => v !== 'All'),
      step: 3,
      profileLabel: 'Religion',
    },
    {
      key: 'preferences.religion.1',
      label: 'Your matches religion',
      validate: (val) => val,
      errMsg: 'Add your matches preferred religion',
      value: 'All',
      select: Object.values((<any>africanData().extraPreferences.find((f) => f.key === 'religion')).values),
      step: 3,
      profileLabel: 'Religion',
    },
  ];
  return data;
};

let cache: any = null;
export const initData = () => {
  Object.defineProperty(window, '__INIT_CONNECT_DATA__', {
    value: () => {
      if (cache) return cache;
      const website = window.location.origin;
      if (website.includes('globeromance')) {
        const data = addRelData(globeRomance());
        cache = data;
        return data;
      }
      if (website.includes('jwdate')) {
        const data = adjustJData(jdData());
        cache = data;
        return data;
      }
      if (website.includes('philippinebeauty')) {
        const data = phillipineData();
        cache = data;
        return data;
      }
      if (website.includes('africa')) {
        const data = addRelData(africanData());
        cache = data;
        return data;
      }
      if (website.includes('disabilitymatch')) {
        const data = addRelData(disabilityMatch());
        cache = data;
        return data;
      }
      if (website.includes('localhost')) {
        const data = adjustJData(jdData());
        // const data = baseInit;
        cache = data;
        return data;
      }
      // ALL ELSE
      const data = baseInit;
      // const data = globeRomance();
      cache = data;
      return data;
    },
    enumerable: false,
  });
};

export const getSubscriptionsDetails = (country: any) => {
  const cheap = {
    planId: 'P-0N683007G3548151HMVLVTPI',
    amount: 4.00,
  };
  const standard = {
    planId: 'P-7H737060D91417529MVLVUHA',
    amount: 12.00,
  };
  const plus = {
    planId: 'P-32696715PU6930204MVMPCUA',
    amount: 29.00,
  };

  const isDev = process.env.REACT_APP_CONNECT_ENV === 'development';
  if (isDev) { return standard; }

  const plusCountries = [
    'Sweden',
    'Norway',
    'United States',
    'Canada',
    'Singapore',
    'Denmark',
    'Belgium',
    'Netherlands',
    'United Kingdom',
    'Japan',
    'China',
    'India',
    'Saudi Arabia',
    'Qatar',
    'United Arab Emirates',
    'France',
    'Germany',
    'Australia',
    'Austria',
  ];

  // if (plusCountries.includes(country)) return plus; // activate later

  const standardCountries = [
    'Israel',
    'Egypt',
    'Brazil',
    'Chile',
    'Argentina',
    'Colombia',
    'Peru',
  ];

  if (standardCountries.includes(country)) return standard;

  const africanCountries = countriesByContinent.find((f) => f.continent === 'Africa')?.countries.map((v) => v.label);
  const southAmericanCountries = countriesByContinent.find((f) => f.continent === 'South America')?.countries.map((v) => v.label);
  const isCheapCountries = africanCountries?.includes(country) || southAmericanCountries?.includes(country);

  if (isCheapCountries) return cheap;

  // All else
  return standard;
};
