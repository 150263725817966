import type { FC } from 'react';
import { LinearProgress } from '@mui/material';
import Logo from 'components/Logo';

interface Props {
  text?: string
}

const Loading:FC<Props> = ({ text }): JSX.Element => (
  <div>
    <div
      style={{
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Logo />
      <LinearProgress color="secondary" sx={{ width: '100%' }} />
      {text && <h4 style={{ padding: '25px' }}>{text}</h4>}
    </div>
  </div>
);

export default Loading;
