/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import type { AccountEntity } from '../../../../server/src/models/Account';

/* **************** EMUMS ****************** */
export enum Paths {
  // connections
  ROOT = '/user',
  NEW_MEMBERS = '/user/new-members',
  SEARCH = '/user/search',
  NEW_PHOTOS = '/user/new-photos',
  RECENTLY_MODIFIED = '/user/recently-modified',
  MATCHES = '/user/matches',
  PROFILE_VIEWS = '/user/profile-views',
  FANS = '/user/fans',
  LIKES = '/user/likes',
  MESSAGES = '/user/messages',
  POPULAR = '/user/popular',
  RECOMMENDED = '/user/recommended',
  // accounts
  MY_ACCOUNT = '/user/my-account',
  MY_PROFILE = '/user/my-profile',
  // Help & Info
  FAQ = '/user/faq',
  TERMS_OF_USE = '/user/terms-of-use',
  CONTACT_US = '/contact-us',
  // Other
  PROFILE = '/user/profile',
  CHAT = '/user/chat',
  RESET_PASSWORD = '/reset-password',
}

export enum Environments {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}

export enum PostMessageEvents {
  ROUTE_NAVIGATE = 'RouteNavigate'
}

export enum WebStorageKeys {
  SESSION_DATA = 'SESSION_DATA',
  PROFILE_VISIT_ID = 'PROFILE_VISIT_ID',
  CHAT_USER_ID = 'CHAT_USER_ID',
  CHAT_USER_PROFILE_DATA = 'CHAT_USER_PROFILE_DATA',
  NEW_LIKES = 'NEW_LIKES',
  NEW_PROFILE_VIEWS = 'NEW_PROFILE_VIEWS',
  NEW_UNREAD_MESSAGES = 'NEW_UNREAD_MESSAGES',
  SYSTEM_START_MESSAGE = 'SYSTEM_START_MESSAGE',
  SEARCH_BASIC_FILTER = 'SEARCH_BASIC_FILTER'
}

export enum SortBy {
  LATEST_CREATED = 'Latest created',
  LATEST_LOGIN = 'Latest login',
  LATEST_MODIFIED = 'Latest modified',
}

export enum CustomFilter {
  LATEST_LIKES_ME = 'LATEST_LIKES_ME',
  LATEST_MY_LIKES = 'LATEST_MY_LIKES',
  LATEST_MODIFIED = 'modified',
  LATEST_LOGIN = 'lastLogin',
  LATEST_CREATED = 'created',
  LATEST_MUTUAL_LIKES = 'LATEST_MUTUAL_LIKES',
  LATEST_PUBLISHED_PHOTOS = 'LATEST_PUBLISHED_PHOTOS',
  LATEST_VIEWS = 'LATEST_VIEWS',
  MOST_POPULAR = 'MOST_POPULAR',
  TOP_MATCHES = 'TOP_MATCHES',
}

/* **************** TYPES ****************** */
export type ThemeModes = 'light'|'dark';

/* **************** INTERFACES ****************** */
export interface ISessionStorageData {
  USER_TOKEN: string,
  THEME_MODE: ThemeModes,
  isAuthenticated: boolean
  profileData: IProfile
  accountData: AccountEntity
  signUp: boolean,
  isLanding: boolean,
}

export interface ISearchFilter {
  gender: string,
  continent: string,
  country: string,
  city: string,
  minAge: number,
  maxAge: number,
  // photoOnly: boolean,
  // These below are always added to preferences
  relationshipStatus: string,
  educationLevel: string,
  // language: string, // Preferred language(s) spoken by the user
  ethnicity: string,
  // minHeight: number, // Height in centimeters or inches
  bodyType: string,
  attractiveness: string,
  drinkingHabits: string,
  hasKids: string,
  wantKids: string,
}

export interface IListProfile {
  gender: 'male' | 'female',
  userName: string,
  dateOfBirth: string,
  continent: string,
  country: string,
  city: string,
  lastLogin: number,
  modified: number,
  created: number,
  headline: string,
  userId: string
  imageSrc: string,
  addedToFavorites: boolean, // merged with several DB's from BE
  totalPictures: number // merged with several DB's from BE
  subscription?: number,
  oneLiner?: string
}
export interface IProfile extends Omit<IListProfile, | 'totalPictures' | 'imageSrc'> {
  // eslint-disable-next-line max-len
  aboutMe: string,
  aboutMyMatch: string,
  pictures: Array<{
    main: boolean,
    url: string
  }>
  blockedUsers: string[], // the blocked profiles this user has blocked
  preferences: Array<IPreferences>
  blocked: boolean
}
export interface IPreferences {
  key: string,
  label: string[], // first index is "Me" second index is "you", if no second index, use first
  values: Array<null|number|string|boolean|Array<string>>
}

export interface IMessages {
  userId: string,
  recieverUserId: string, // userID of other person
  youHaveRead: boolean, // this will be set to true when open new msg and lastMessageWasOpened will be set to true
  profilePic: string,
  userName: string,
  location: string,
  dateOfBirth: string,
  lastMessageSent: number,
  userLastLogin: number,
  conversationId: string,
  archived: boolean
  blocked: boolean
}

export interface IMessage extends IMessages {
  lastMessageWasOpened: boolean, // this means last message has been read, only update when youHaveRead updates to false, same request
  conversation: Array<{
    from: string // userId,
    message: string,
    timestamp: number,
  }>
}

export interface IProfileViews {
  // userId: string, // my userID does not need to be included in client response
  // userIds: Array<string>, // does not need to be included in client response
  newNotSeen: number // reset every time user visits the page
}

export interface ILikesMe {
  // userId: string, // my userID does not need to be included in client response
  // userIds: Array<string>, // does not need to be included in client response
  newNotSeen: number // reset every time user visits the page
}

/* **************** CONSTS ****************** */

const europeanCountries = [
  {
    label: 'Albania',
    code: 'AL',
  },
  {
    label: 'Andorra',
    code: 'AD',
  },
  {
    label: 'Austria',
    code: 'AT',
  },
  {
    label: 'Belarus',
    code: 'BY',
  },
  {
    label: 'Belgium',
    code: 'BE',
  },
  {
    label: 'Bosnia and Herzegovina',
    code: 'BA',
  },
  {
    label: 'Bulgaria',
    code: 'BG',
  },
  {
    label: 'Croatia',
    code: 'HR',
  },
  {
    label: 'Cyprus',
    code: 'CY',
  },
  {
    label: 'Czech Republic',
    code: 'CZ',
  },
  {
    label: 'Denmark',
    code: 'DK',
  },
  {
    label: 'Estonia',
    code: 'EE',
  },
  {
    label: 'Finland',
    code: 'FI',
  },
  {
    label: 'France',
    code: 'FR',
  },
  {
    label: 'Germany',
    code: 'DE',
  },
  {
    label: 'Greece',
    code: 'GR',
  },
  {
    label: 'Hungary',
    code: 'HU',
  },
  {
    label: 'Iceland',
    code: 'IS',
  },
  {
    label: 'Ireland',
    code: 'GB',
  },
  {
    label: 'Italy',
    code: 'IT',
  },
  {
    label: 'Kosovo',
    code: 'XK',
  },
  {
    label: 'Latvia',
    code: 'LV',
  },
  {
    label: 'Liechtenstein',
    code: 'LI',
  },
  {
    label: 'Lithuania',
    code: 'LT',
  },
  {
    label: 'Luxembourg',
    code: 'LU',
  },
  {
    label: 'Malta',
    code: 'MT',
  },
  {
    label: 'Moldova',
    code: 'MD',
  },
  {
    label: 'Monaco',
    code: 'MC',
  },
  {
    label: 'Montenegro',
    code: 'ME',
  },
  {
    label: 'Netherlands',
    code: 'BQ',
  },
  {
    label: 'North Macedonia',
    code: 'MK',
  },
  {
    label: 'Norway',
    code: 'NO',
  },
  {
    label: 'Poland',
    code: 'PL',
  },
  {
    label: 'Portugal',
    code: 'PT',
  },
  {
    label: 'Romania',
    code: 'RO',
  },
  {
    label: 'Russia',
    code: 'RU',
  },
  {
    label: 'San Marino',
    code: 'SM',
  },
  {
    label: 'Serbia',
    code: 'RS',
  },
  {
    label: 'Slovakia',
    code: 'SK',
  },
  {
    label: 'Slovenia',
    code: 'SI',
  },
  {
    label: 'Spain',
    code: 'ES',
  },
  {
    label: 'Sweden',
    code: 'SE',
  },
  {
    label: 'Switzerland',
    code: 'CH',
  },
  {
    label: 'Ukraine',
    code: 'UA',
  },
  {
    label: 'United Kingdom',
    code: 'GB',
  },
  {
    label: 'Vatican City',
    code: 'VA',
  },
];

const africanCountries = [
  {
    label: 'Algeria',
    code: 'DZ',
  },
  {
    label: 'Angola',
    code: 'AO',
  },
  {
    label: 'Benin',
    code: 'BJ',
  },
  {
    label: 'Botswana',
    code: 'BW',
  },
  {
    label: 'Burkina Faso',
    code: 'BF',
  },
  {
    label: 'Burundi',
    code: 'BI',
  },
  {
    label: 'Cabo Verde',
    code: 'CV',
  },
  {
    label: 'Cameroon',
    code: 'CM',
  },
  {
    label: 'Central African Republic',
    code: 'CF',
  },
  {
    label: 'Chad',
    code: 'TD',
  },
  {
    label: 'Comoros',
    code: 'KM',
  },
  {
    label: 'Democratic Republic of the Congo',
    code: 'CD',
  },
  {
    label: 'Djibouti',
    code: 'DJ',
  },
  {
    label: 'Egypt',
    code: 'EG',
  },
  {
    label: 'Equatorial Guinea',
    code: 'GQ',
  },
  {
    label: 'Eritrea',
    code: 'ER',
  },
  {
    label: 'Eswatini',
    code: 'SZ',
  },
  {
    label: 'Ethiopia',
    code: 'ET',
  },
  {
    label: 'Gabon',
    code: 'GA',
  },
  {
    label: 'Gambia',
    code: 'GM',
  },
  {
    label: 'Ghana',
    code: 'GH',
  },
  {
    label: 'Guinea',
    code: 'PG',
  },
  {
    label: 'Guinea-Bissau',
    code: 'GW',
  },
  {
    label: 'Ivory Coast',
    code: 'CI',
  },
  {
    label: 'Kenya',
    code: 'KE',
  },
  {
    label: 'Lesotho',
    code: 'LS',
  },
  {
    label: 'Liberia',
    code: 'LR',
  },
  {
    label: 'Libya',
    code: 'LY',
  },
  {
    label: 'Madagascar',
    code: 'MG',
  },
  {
    label: 'Malawi',
    code: 'MW',
  },
  {
    label: 'Mali',
    code: 'SO',
  },
  {
    label: 'Mauritania',
    code: 'MR',
  },
  {
    label: 'Mauritius',
    code: 'MU',
  },
  {
    label: 'Morocco',
    code: 'MA',
  },
  {
    label: 'Mozambique',
    code: 'MZ',
  },
  {
    label: 'Namibia',
    code: 'NA',
  },
  {
    label: 'Niger',
    code: 'NG',
  },
  {
    label: 'Nigeria',
    code: 'NG',
  },
  {
    label: 'Rwanda',
    code: 'RW',
  },
  {
    label: 'Sao Tome and Principe',
    code: 'ST',
  },
  {
    label: 'Senegal',
    code: 'SN',
  },
  {
    label: 'Seychelles',
    code: 'SC',
  },
  {
    label: 'Sierra Leone',
    code: 'SL',
  },
  {
    label: 'Somalia',
    code: 'SO',
  },
  {
    label: 'South Africa',
    code: 'ZA',
  },
  {
    label: 'South Sudan',
    code: 'SS',
  },
  {
    label: 'Sudan',
    code: 'SS',
  },
  {
    label: 'Tanzania',
    code: 'TZ',
  },
  {
    label: 'Togo',
    code: 'TG',
  },
  {
    label: 'Tunisia',
    code: 'TN',
  },
  {
    label: 'Uganda',
    code: 'UG',
  },
  {
    label: 'Zambia',
    code: 'ZM',
  },
  {
    label: 'Zimbabwe',
    code: 'ZW',
  },
];

const asianCountries = [
  {
    label: 'Afghanistan',
    code: 'AF',
  },
  {
    label: 'Armenia',
    code: 'AM',
  },
  {
    label: 'Azerbaijan',
    code: 'AZ',
  },
  {
    label: 'Bahrain',
    code: 'BH',
  },
  {
    label: 'Bangladesh',
    code: 'BD',
  },
  {
    label: 'Bhutan',
    code: 'BT',
  },
  {
    label: 'Brunei',
    code: 'BN',
  },
  {
    label: 'Cambodia',
    code: 'KH',
  },
  {
    label: 'China',
    code: 'MO',
  },
  {
    label: 'Georgia',
    code: 'GS',
  },
  {
    label: 'India',
    code: 'IO',
  },
  {
    label: 'Indonesia',
    code: 'ID',
  },
  {
    label: 'Iran',
    code: 'IR',
  },
  {
    label: 'Iraq',
    code: 'IQ',
  },
  {
    label: 'Israel',
    code: 'IL',
  },
  {
    label: 'Japan',
    code: 'JP',
  },
  {
    label: 'Jordan',
    code: 'JO',
  },
  {
    label: 'Kazakhstan',
    code: 'KZ',
  },
  {
    label: 'Kuwait',
    code: 'KW',
  },
  {
    label: 'Kyrgyzstan',
    code: 'KG',
  },
  {
    label: 'Laos',
    code: 'LA',
  },
  {
    label: 'Lebanon',
    code: 'LB',
  },
  {
    label: 'Malaysia',
    code: 'MY',
  },
  {
    label: 'Maldives',
    code: 'MV',
  },
  {
    label: 'Mongolia',
    code: 'MN',
  },
  {
    label: 'Myanmar',
    code: 'MM',
  },
  {
    label: 'Nepal',
    code: 'NP',
  },
  {
    label: 'North Korea',
    code: 'KP',
  },
  {
    label: 'Oman',
    code: 'RO',
  },
  {
    label: 'Pakistan',
    code: 'PK',
  },
  {
    label: 'Palestine',
    code: 'PS',
  },
  {
    label: 'Philippines',
    code: 'PH',
  },
  {
    label: 'Qatar',
    code: 'QA',
  },
  {
    label: 'Saudi Arabia',
    code: 'SA',
  },
  {
    label: 'Singapore',
    code: 'SG',
  },
  {
    label: 'South Korea',
    code: 'KR',
  },
  {
    label: 'Sri Lanka',
    code: 'LK',
  },
  {
    label: 'Syria',
    code: 'SY',
  },
  {
    label: 'Tajikistan',
    code: 'TJ',
  },
  {
    label: 'Thailand',
    code: 'TH',
  },
  {
    label: 'Timor-Leste',
    code: 'TL',
  },
  {
    label: 'Turkey',
    code: 'TR',
  },
  {
    label: 'Turkmenistan',
    code: 'TM',
  },
  {
    label: 'United Arab Emirates',
    code: 'AE',
  },
  {
    label: 'Uzbekistan',
    code: 'UZ',
  },
  {
    label: 'Vietnam',
    code: 'VN',
  },
  {
    label: 'Yemen',
    code: 'YE',
  },
];

const southAmericanCountries = [
  {
    label: 'Argentina',
    code: 'AR',
  },
  {
    label: 'Bolivia',
    code: 'BO',
  },
  {
    label: 'Brazil',
    code: 'BR',
  },
  {
    label: 'Chile',
    code: 'CL',
  },
  {
    label: 'Colombia',
    code: 'CO',
  },
  {
    label: 'Ecuador',
    code: 'EC',
  },
  {
    label: 'Guyana',
    code: 'GY',
  },
  {
    label: 'Paraguay',
    code: 'PY',
  },
  {
    label: 'Peru',
    code: 'PE',
  },
  {
    label: 'Suriname',
    code: 'SR',
  },
  {
    label: 'Uruguay',
    code: 'UY',
  },
  {
    label: 'Venezuela',
    code: 'VE',
  },
];

const centralAmericanCountries = [
  {
    label: 'Belize',
    code: 'BZ',
  },
  {
    label: 'Costa Rica',
    code: 'CR',
  },
  {
    label: 'El Salvador',
    code: 'SV',
  },
  {
    label: 'Guatemala',
    code: 'GT',
  },
  {
    label: 'Honduras',
    code: 'HN',
  },
  {
    label: 'Nicaragua',
    code: 'NI',
  },
  {
    label: 'Panama',
    code: 'PA',
  },
];

const australiaOceaniaCountries = [
  {
    label: 'Australia',
    code: 'AU',
  },
  {
    label: 'Fiji',
    code: 'FJ',
  },
  {
    label: 'Kiribati',
    code: 'KI',
  },
  {
    label: 'Marshall Islands',
    code: 'MH',
  },
  {
    label: 'Micronesia',
    code: 'FM',
  },
  {
    label: 'Nauru',
    code: 'NR',
  },
  {
    label: 'New Zealand',
    code: 'NZ',
  },
  {
    label: 'Palau',
    code: 'PW',
  },
  {
    label: 'Papua New Guinea',
    code: 'PG',
  },
  {
    label: 'Samoa',
    code: 'AS',
  },
  {
    label: 'Solomon Islands',
    code: 'SB',
  },
  {
    label: 'Tonga',
    code: 'TO',
  },
  {
    label: 'Tuvalu',
    code: 'TV',
  },
  {
    label: 'Vanuatu',
    code: 'VU',
  },
];

const northAmericanCountries = [
  {
    label: 'Canada',
    code: 'CA',
  },
  {
    label: 'United States',
    code: 'VI',
  },
  {
    label: 'Mexico',
    code: 'MX',
  },
];

export const countriesByContinent = [
  {
    continent: 'All',
    countries: [],
  },
  {
    continent: 'Africa',
    countries: africanCountries,
  },
  {
    continent: 'Asia',
    countries: asianCountries,
  },
  {
    continent: 'Europe',
    countries: europeanCountries,
  },
  {
    continent: 'South America',
    countries: southAmericanCountries,
  },
  {
    continent: 'North America',
    countries: northAmericanCountries,
  },
  {
    continent: 'Central America',
    countries: centralAmericanCountries,
  },
  {
    continent: 'Australia (Oceania)',
    countries: australiaOceaniaCountries,
  },
];
