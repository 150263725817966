import { Environments } from 'types/globals';

const config = {
  [Environments.PRODUCTION]: {
    apiEndpoint: `${window.location.origin}/api/v1`,
  },
  [Environments.DEVELOPMENT]: {
    apiEndpoint: 'http://localhost:3030/api/v1',
  },
  [Environments.STAGING]: {
    apiEndpoint: 'https://connect-platform-test-500cae9ee782.herokuapp.com/api/v1',
  },
};

export default config;
