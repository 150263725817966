/* eslint-disable no-dupe-keys */
import { countriesByContinent } from './globals';

const generateAgeArray = () => {
  const ageArray: any[] = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 18; i <= 100; i++) {
    ageArray.push(i);
  }
  return ageArray;
};

const getSharedPreferences = () => {
  // const filterUniqueValues = (arr: any) => Array.from(new Set(arr));
  const Gender = {
    key: 'gender',
    values: {
      Male: 'male',
      Female: 'female',
    },
  };

  const Location = {
    key: 'location',
    values: ['All', ...countriesByContinent.map((c) => [...c.countries]).flat().map((c: any) => c.label)]
      .sort(),
    multiSelect: true,
  };

  const AgeInterval = {
    key: 'ageInterval',
    values: generateAgeArray(),
    multiSelect: true,
  };

  let RelationshipStatus = {
    key: 'relationshipStatus',
    values: {
      All: 'All',
      Single: 'Single',
      InARelationship: 'In a relationship',
      Complicated: 'Complicated',
      Divorced: 'Divorced',
      DivorcedFree: 'Divorced (free to remarry)',
      Widowed: 'Widowed',
    },
    multiSelect: true,
  };

  if (window.__INIT_CONNECT_DATA__ && window.__INIT_CONNECT_DATA__().type !== 'jd') {
    RelationshipStatus = {
      key: 'relationshipStatus',
      values: {
        All: 'All',
        Single: 'Single',
        InARelationship: 'In a relationship',
        Complicated: 'Complicated',
        Divorced: 'Divorced',
        Widowed: 'Widowed',
      },
      multiSelect: true,
    } as any;
  }

  const EducationLevel = {
    key: 'educationLevel',
    values: {
      All: 'All',
      AskLater: 'Ask later',
      HighSchool: 'High school',
      College: 'College',
      Bachelor: 'Bachelor',
      Master: 'Master',
      Doctorate: 'Doctorate',
    },
    multiSelect: true,
  };

  const Ethnicity = {
    key: 'ethnicity',
    values: {
      All: 'All',
      Asian: 'Asian',
      Caribbean: 'Caribbean',
      African: 'African',
      White: 'White',
      Arab: 'Arab',
      Latin: 'Latin',
      Mixed: 'Mixed',
      Other: 'Other',
    },
    multiSelect: true,
  };

  const BodyType = {
    key: 'bodyType',
    values: {
      All: 'All',
      Slim: 'Slim',
      Athletic: 'Athletic',
      Average: 'Average',
      Curvy: 'Curvy',
      PlusSize: 'Plus-size',
    },
    multiSelect: true,
  };

  const Attractiveness = {
    key: 'attractiveness',
    values: {
      All: 'All',
      AskLater: 'Ask later',
      BelowAverage: 'Below average',
      Average: 'Average',
      Attractive: 'Attractive',
      VeryAttractive: 'Very attractive',
    },
    multiSelect: true,
  };

  const DrinkingHabits = {
    key: 'drinkingHabits',
    values: {
      All: 'All',
      AskLater: 'Ask later',
      NonDrinker: 'Non drinker',
      SocialDrinker: 'Social drinker',
      RegularDrinker: 'Regular drinker',
    },
    multiSelect: true,
  };

  const HasKids = {
    key: 'hasKids',
    values: {
      All: 'All',
      Yes: 'Yes',
      No: 'No',
      AskLater: 'Ask later',
    },
    multiSelect: true,
  };

  const WantKids = {
    key: 'wantKids',
    values: {
      All: 'All',
      Yes: 'Yes',
      No: 'No',
      Maybe: 'Maybe',
      AskLater: 'Ask later',
    },
    multiSelect: true,
  };

  const LookingFor = {
    key: 'lookingFor',
    values: {
      All: 'All',
      Friends: 'Friends',
      WeWillSee: 'We will see',
      Marriage: 'Marriage',
      Relationship: 'Relationship',
      OnlineFriends: 'Online friends',
    },
    multiSelect: true,
  };

  const Income = {
    key: 'income',
    values: {
      All: 'All',
      AskMeLater: 'Ask me later',
      Struggling: 'Struggling',
      Enough: 'Enough',
      Wealthy: 'Wealthy',
    },
    multiSelect: true,
  };

  const Languages = {
    key: 'languages',
    values: {
      All: 'All',
      Albanian: 'Albanian',
      Armenian: 'Armenian',
      Azerbaijani: 'Azerbaijani',
      Basque: 'Basque',
      Belarusian: 'Belarusian',
      Bosnian: 'Bosnian',
      Breton: 'Breton',
      Bulgarian: 'Bulgarian',
      Catalan: 'Catalan',
      Croatian: 'Croatian',
      Czech: 'Czech',
      Danish: 'Danish',
      Dutch: 'Dutch',
      English: 'English',
      Estonian: 'Estonian',
      Faroese: 'Faroese',
      Finnish: 'Finnish',
      French: 'French',
      Galician: 'Galician',
      Georgian: 'Georgian',
      German: 'German',
      Greek: 'Greek',
      Greenlandic: 'Greenlandic',
      Hungarian: 'Hungarian',
      Icelandic: 'Icelandic',
      Irish: 'Irish',
      Italian: 'Italian',
      Latvian: 'Latvian',
      Lithuanian: 'Lithuanian',
      Luxembourgish: 'Luxembourgish',
      Macedonian: 'Macedonian',
      Maltese: 'Maltese',
      Norwegian: 'Norwegian',
      Polish: 'Polish',
      Portuguese: 'Portuguese',
      Romanian: 'Romanian',
      Russian: 'Russian',
      Serbian: 'Serbian',
      Slovak: 'Slovak',
      Slovenian: 'Slovenian',
      Sorbian: 'Sorbian',
      Spanish: 'Spanish',
      Swedish: 'Swedish',
      Turkish: 'Turkish',
      Ukrainian: 'Ukrainian',
      Welsh: 'Welsh',
    },
    multiSelect: true,
  };

  const Height = {
    key: 'height',
    values: {
      '120 cm': '120 cm',
      '121 cm': '121 cm',
      '122 cm': '122 cm',
      '123 cm': '123 cm',
      '124 cm': '124 cm',
      '125 cm': '125 cm',
      '126 cm': '126 cm',
      '127 cm': '127 cm',
      '128 cm': '128 cm',
      '129 cm': '129 cm',
      '130 cm': '130 cm',
      '131 cm': '131 cm',
      '132 cm': '132 cm',
      '133 cm': '133 cm',
      '134 cm': '134 cm',
      '135 cm': '135 cm',
      '136 cm': '136 cm',
      '137 cm': '137 cm',
      '138 cm': '138 cm',
      '139 cm': '139 cm',
      '140 cm': '140 cm',
      '141 cm': '141 cm',
      '142 cm': '142 cm',
      '143 cm': '143 cm',
      '144 cm': '144 cm',
      '145 cm': '145 cm',
      '146 cm': '146 cm',
      '147 cm': '147 cm',
      '148 cm': '148 cm',
      '149 cm': '149 cm',
      '150 cm': '150 cm',
      '151 cm': '151 cm',
      '152 cm': '152 cm',
      '153 cm': '153 cm',
      '154 cm': '154 cm',
      '155 cm': '155 cm',
      '156 cm': '156 cm',
      '157 cm': '157 cm',
      '158 cm': '158 cm',
      '159 cm': '159 cm',
      '160 cm': '160 cm',
      '161 cm': '161 cm',
      '162 cm': '162 cm',
      '163 cm': '163 cm',
      '164 cm': '164 cm',
      '165 cm': '165 cm',
      '166 cm': '166 cm',
      '167 cm': '167 cm',
      '168 cm': '168 cm',
      '169 cm': '169 cm',
      '170 cm': '170 cm',
      '171 cm': '171 cm',
      '172 cm': '172 cm',
      '173 cm': '173 cm',
      '174 cm': '174 cm',
      '175 cm': '175 cm',
      '176 cm': '176 cm',
      '177 cm': '177 cm',
      '178 cm': '178 cm',
      '179 cm': '179 cm',
      '180 cm': '180 cm',
      '181 cm': '181 cm',
      '182 cm': '182 cm',
      '183 cm': '183 cm',
      '184 cm': '184 cm',
      '185 cm': '185 cm',
      '186 cm': '186 cm',
      '187 cm': '187 cm',
      '188 cm': '188 cm',
      '189 cm': '189 cm',
      '190 cm': '190 cm',
      '191 cm': '191 cm',
      '192 cm': '192 cm',
      '193 cm': '193 cm',
      '194 cm': '194 cm',
      '195 cm': '195 cm',
      '196 cm': '196 cm',
      '197 cm': '197 cm',
      '198 cm': '198 cm',
      '199 cm': '199 cm',
      '200+ cm': '200+ cm',
    },
  };
  return {
    Gender,
    RelationshipStatus,
    AgeInterval,
    EducationLevel,
    Ethnicity,
    BodyType,
    Attractiveness,
    DrinkingHabits,
    HasKids,
    WantKids,
    LookingFor,
    Income,
    Languages,
    Height,
    Location,
  };
};

export default () => ({ ...getSharedPreferences() });
