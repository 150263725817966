import config from 'config';
import { WebStorageKeys } from 'types/globals';
import { getWebStorage, updateSessionStorageData } from 'utils/helpers';
import type { IFilter } from '../../../../server/src/types/globals';

const getUserToken = () => {
  const authToken = getWebStorage(WebStorageKeys.SESSION_DATA) || {};
  const token = authToken.USER_TOKEN;
  return token;
};

enum MethodTypes {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

const baseUrl = `${config[process.env.REACT_APP_CONNECT_ENV as string].apiEndpoint}`;

const buildUrl = (path) => `${baseUrl}${path}`;

const fetchMethod = async (path, method, payload, exactUrl?: string) => {
  try {
    const res = await fetch(exactUrl || buildUrl(path), {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getUserToken()}`,
      },
      ...payload && { body: JSON.stringify(payload) },
    });
    const data = await res.json();
    if (data.data.token) {
      updateSessionStorageData({
        USER_TOKEN: data.data.token,
      });
    }
    return data;
  } catch (err) {
    console.log('failed');
    console.log(err);
    return false;
  }
};

export const API = {
  login: async ({ pwd, mail }) => {
    const path = `/account?pwd=${pwd}&mail=${mail}`;
    const data = await fetchMethod(path, MethodTypes.GET, null);
    return data;
  },
  getProfiles: async (filter: IFilter) => {
    const path = '/profile';
    const data = await fetchMethod(
      path,
      MethodTypes.POST,
      {
        filter: {
          ...filter,
          max: 30,
        },
      },
    );
    return data;
  },
  getProfile: async ({ userId }) => {
    const path = `/profile?userId=${userId}`;
    const data = await fetchMethod(path, MethodTypes.GET, null);
    return data;
  },
  getAccount: async () => {
    // gets data from JWT token
    const path = '/account';
    const data = await fetchMethod(path, MethodTypes.GET, null);
    return data;
  },
  updateAccount: async (payload: any) => {
    const path = '/account';
    const data = await fetchMethod(path, MethodTypes.PUT, payload);
    return data;
  },
  updateProfile: async (payload: any) => {
    const path = '/profile';
    const data = await fetchMethod(path, MethodTypes.PUT, payload);
    return data;
  },
  getConversation: async ({ conversationId }) => {
    const path = `/conversation?conversationId=${conversationId}`;
    const data = await fetchMethod(path, MethodTypes.GET, null);
    return data;
  },
  updateConversation: async (payload: any) => {
    const path = '/conversation';
    const data = await fetchMethod(path, MethodTypes.PUT, payload);
    return data;
  },
  updateMessage: async (payload: any) => {
    const path = '/messages';
    const data = await fetchMethod(path, MethodTypes.PUT, payload);
    return data;
  },
  getMessages: async (filter: IFilter) => {
    const path = '/messages';
    const data = await fetchMethod(path, MethodTypes.POST, { filter });
    return data;
  },
  newConversation: async (conversation: any) => {
    const path = '/messages';
    const data = await fetchMethod(path, MethodTypes.POST, conversation);
    return data;
  },
  newLike: async (like: any) => {
    const path = '/likes';
    const data = await fetchMethod(path, MethodTypes.POST, like);
    return data;
  },
  deleteLike: async (like: any) => {
    const path = '/likes';
    const data = await fetchMethod(path, MethodTypes.DELETE, like);
    return data;
  },
  getLikes: async (filter: any) => {
    const path = '/likes';
    const data = await fetchMethod(path, MethodTypes.POST, { filter });
    return data;
  },
  getProfileViews: async (filter: any) => {
    const path = '/profile-views';
    const data = await fetchMethod(path, MethodTypes.POST, { filter });
    return data;
  },
  newProfileView: async (payload: any) => {
    const path = '/profile-views';
    const data = await fetchMethod(path, MethodTypes.POST, payload);
    return data;
  },
  resetProfileViews: async () => {
    const path = '/profile-views';
    const data = await fetchMethod(path, MethodTypes.PUT, null);
    return data;
  },
  createAccount: async (payload: any) => {
    const params = new URLSearchParams(window.location.search);
    const s = params.get('s') || 'other';
    const path = '/account';
    const data = await fetchMethod(path, MethodTypes.POST, { ...payload, s });
    return data;
  },
  createProfile: async (payload: any) => {
    const path = '/profile';
    const data = await fetchMethod(path, MethodTypes.POST, payload);
    return data;
  },
  getSystemMessage: async () => {
    const path = '/system-message';
    const data = await fetchMethod(path, MethodTypes.GET, null);
    return data;
  },
  deleteAccount: async () => {
    const path = '/delete-account';
    const data = await fetchMethod(path, MethodTypes.DELETE, null);
    return data;
  },
  reportUser: async (payload: any) => {
    const path = '/report';
    const data = await fetchMethod(path, MethodTypes.POST, payload);
    return data;
  },
  resetPwdRequest: async ({ mail }) => {
    const path = `/reset-password?mail=${mail}`;
    const data = await fetchMethod(path, MethodTypes.GET, null);
    return data;
  },
  resetPwd: async (payload: any) => {
    const path = '/reset-password';
    const data = await fetchMethod(path, MethodTypes.POST, payload);
    return data;
  },
  blogPosts: async (filter: IFilter) => {
    const path = '/';
    const data = await fetchMethod(
      path,
      MethodTypes.POST,
      { filter },
      'https://my-general-server-d56381110426.herokuapp.com/api/v1/baas/article',
    );
    return data;
  },
  getMemberStatus: async () => {
    const path = '/payment-status';
    const data = await fetchMethod(path, MethodTypes.GET, null);
    return data;
  },
  setMemberStatus: async (payload: any) => {
    const path = '/payment-status';
    const data = await fetchMethod(path, MethodTypes.POST, payload);
    return data;
  },
  sendMail: async (payload: any) => {
    const path = '/send-mail';
    const data = await fetchMethod(path, MethodTypes.POST, payload);
    return data;
  },
};
