import type { FC } from 'react';
import { Paths } from 'types/globals';
import {
  Box,
} from '@mui/material';

interface Props {}

const Logo:FC<Props> = (): JSX.Element => {
  const logoClick = () => {
    window.location.pathname = Paths.ROOT;
  };

  return (
    <div id="logo-container">
      <Box
        onClick={logoClick}
        id="date-logo"
        style={{
          maxWidth: '60vw',
          cursor: 'pointer',
          ...window.__INIT_CONNECT_DATA__().logoStyle(),
        }}
      >
        {window.__INIT_CONNECT_DATA__().logoText}
      </Box>
    </div>
  );
};

export default Logo;
