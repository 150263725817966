/* eslint-disable import/no-extraneous-dependencies */
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.css';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import Loading from 'components/LoadingBar';
import { isAuth, initData } from 'utils/helpers';
import type {
  ISessionStorageData,
} from 'types/globals';

const App = React.lazy(() => import('./App'));
const Login = React.lazy(() => import('./components/LoginAlt'));
const SignUp = React.lazy(() => import('./components/SignUp'));
const ResetPassword = React.lazy(() => import('pages/ResetPassword'));
const Landing = React.lazy(() => import('pages/Landing'));
const Faq = React.lazy(() => import('pages/Faq'));
const TermsOfUse = React.lazy(() => import('pages/TermsOfUse'));
const PrivacyPolicy = React.lazy(() => import('pages/PrivacyPolicy'));
const HeaderNotAuth = React.lazy(() => import('./components/HeaderNotAuth'));
const Footer = React.lazy(() => import('./components/Footer'));
const ContactUs = React.lazy(() => import('pages/ContactUs'));
const RefundPolicy = React.lazy(() => import('components/RefundPolicy'));

window.IS_BELOW_SM = window.innerWidth <= 950;
window.__HOLD_TEMP_ = {};

initData();

function addStyleToHead() {
  const styleTag: any = document.createElement('style');
  styleTag.id = 'connect-platform-body-style';
  styleTag.type = 'text/css';

  const style = `body { background: ${window.__INIT_CONNECT_DATA__().bodyStyle.background} }`;

  if (styleTag.styleSheet) {
    // For IE
    styleTag.styleSheet.cssText = style;
  } else {
    // For other browsers
    styleTag.appendChild(document.createTextNode(style));
  }

  // Add the style tag to the head of the document
  document.head.appendChild(styleTag);
}

addStyleToHead();

window.__INIT_CONNECT_DATA__().gVerificationScript();

const root = createRoot(document.getElementById('root') as HTMLElement);

const renderDom = (content: JSX.Element) => {
  root.render(
    <div style={{ ...window.__INIT_CONNECT_DATA__().bodyStyle }}>
      <React.Suspense fallback={<Loading />}>
        {content}
      </React.Suspense>
    </div>,
  );
};

const renderApp = (args) => {
  renderDom(
    <Provider store={store}>
      <App initData={args} />
    </Provider>,
  );
};

const renderLogin = () => {
  renderDom(<Login />);
};

const renderLoading = () => {
  renderDom(<Loading />);
};

renderLoading();

const init = async () => {
  const {
    THEME_MODE,
    isAuthenticated,
    profileData,
    accountData,
    signUp,
    isLanding,
  } = await isAuth() as ISessionStorageData;

  if (isAuthenticated) {
    renderApp({
      THEME_MODE,
      profileData,
      accountData,
    });
  } else if (signUp) {
    renderDom(<SignUp />);
  } else if (isLanding) {
    renderDom(<Landing />);
  } else if (window.location.pathname.includes('/faq')) {
    renderDom((
      <>
        <HeaderNotAuth />
        <Faq />
        <Footer />
      </>
    ));
  } else if (window.location.pathname.includes('/terms-of-use')) {
    renderDom((
      <>
        <HeaderNotAuth />
        <TermsOfUse />
        <Footer />
      </>
    ));
  } else if (window.location.pathname.includes('/privacy-policy')) {
    renderDom((
      <>
        <HeaderNotAuth />
        <PrivacyPolicy />
        <Footer />
      </>
    ));
  } else if (window.location.pathname.includes('/contact-us')) {
    renderDom((
      <>
        <HeaderNotAuth />
        <ContactUs />
        <Footer />
      </>
    ));
  } else if (window.location.pathname.includes('/refund-policy')) {
    renderDom((
      <>
        <HeaderNotAuth />
        <RefundPolicy />
        <Footer />
      </>
    ));
  } else {
    renderLogin();
  }
};

if (window.location.pathname.includes('/reset-password')) {
  renderDom(<ResetPassword />);
} else {
  init();
}

declare global {
  interface Window {
    IS_BELOW_SM: any;
    INIT_DATA_FETCH: any,
    GLOBAL_APP_CUSTOM_VAL: any
    BACKUP_DEFAULT_PICTURE: any
    FORCE_FETCH_NEW_MESSAGES: any
    DO_NOT_UPDATE_MESSAGES_FROM_APP: any
    __INIT_CONNECT_DATA__: any,
    __HOLD_TEMP_: any,
  }
}
